var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-popup',_vm._b({attrs:{"title":_vm.dialogTitle,"icon":_vm.UtilGetIcon(_vm.getPageIcon),"width":_vm.width,"height":_vm.height},on:{"save":_vm.save},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_vm._t("activator",null,null,scope)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'base-popup',_vm.$attrs,false),[_c('v-container',{staticClass:"d-flex",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"d-flex fill-height"},[(_vm.selectType !== 'position')?_c('v-col',{staticClass:"fill-height"},[_c('base-panel',{staticClass:"fill-height",attrs:{"title":"Подразделения"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[0]),callback:function ($$v) {_vm.$set(_vm.search, 0, $$v)},expression:"search[0]"}}),_c('div',{staticClass:"v-treeview theme--light block__content fill-height d-flex flex-column"},[(_vm.selectType === 'partition')?_c('div',{staticClass:"v-treeview-node"},[_c('div',{staticClass:"v-treeview-node__root"},[_c('v-simple-checkbox',{staticStyle:{"margin-left":"30px","margin-right":"0"},attrs:{"value":_vm.selectedAll,"ripple":false,"color":"var(--v-accent-base)"},on:{"input":function ($event) {
                      _vm.selectedAll = $event;
                      _vm.selectPartitions = $event ? _vm.partitions : [];
                    }}}),_c('div',{staticClass:"v-treeview-node__content"},[_c('div',{staticClass:"v-treeview-node__label"},[_vm._v("Все подразделения")])])],1)]):_vm._e(),_c('v-treeview',{staticClass:"fill-height",staticStyle:{"overflow-y":"auto"},attrs:{"value":_vm.selectPartitions,"items":_vm.partitions,"search":_vm.search[0],"selection-type":_vm.selectType === 'positionPartition' ? 'independent' : null,"item-key":"Id","item-text":"Name","item-children":"Children","selectable":"","return-object":""},on:{"input":_vm.setSelectPartitions},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                    var item = ref.item;
return [(
                    _vm.selectType !== 'positionPartition' ||
                    !item.selectPositions.length
                  )?_c('span',[_vm._v(" "+_vm._s(item.Name)+" ")]):_vm._e(),(
                    _vm.selectType === 'positionPartition' &&
                    item.selectPositions.length
                  )?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.Name + " (" + item.selectPositions.length + ")")+" ")]):_vm._e()]}}],null,false,1241382540)})],1)],1)],1):_vm._e(),(
          _vm.selectType === 'position' ||
          _vm.selectType === 'employee' ||
          _vm.selectType === 'positionPartition'
        )?_c('v-col',{staticClass:"fill-height"},[_c('base-panel',{staticClass:"fill-height",attrs:{"title":"Должности"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[1]),callback:function ($$v) {_vm.$set(_vm.search, 1, $$v)},expression:"search[1]"}}),_c('base-table',{attrs:{"items":_vm.selectType === 'positionPartition' ? _vm.positions : _vm.filterPositions,"search-text":_vm.search[1],"headers":_vm.positionHeaders,"hide-default-footer":"","showSettings":false},scopedSlots:_vm._u([{key:"body.append",fn:function(ref){
        var headers = ref.headers;
return [(!_vm.positionsLoading)?_c('tr',[(
                    _vm.selectType === 'positionPartition' &&
                    (!_vm.selectPartitions.length ||
                      (_vm.selectPartitions.length && _vm.positions.length === 0))
                  )?_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(!_vm.selectPartitions.length ? "Выберите подразделение!" : "В подразделении нет должностей!")+" ")]):_vm._e()]):_vm._e()]}}],null,false,3338607092),model:{value:(_vm.selectPositions),callback:function ($$v) {_vm.selectPositions=$$v},expression:"selectPositions"}})],1)],1):_vm._e(),(_vm.selectType === 'employee')?_c('v-col',{staticClass:"fill-height"},[_c('base-panel',{staticClass:"fill-height",attrs:{"title":"Сотрудники"}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.search[2]),callback:function ($$v) {_vm.$set(_vm.search, 2, $$v)},expression:"search[2]"}}),_c('base-table',{attrs:{"search-text":_vm.search[2],"items":_vm.filteredEmployees,"headers":_vm.employeeHeaders,"hide-default-footer":"","showSettings":false},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name ? item.Name : "")+" ")]}}],null,false,2229140470),model:{value:(_vm.selectEmployees),callback:function ($$v) {_vm.selectEmployees=$$v},expression:"selectEmployees"}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }